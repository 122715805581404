import React, { useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme } from "./MUITheme";
import { history } from "app/history";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  refreshToken,
  userSelector,
} from "./features/user/userSlice";

// components
import ForgotPassword from "./features/user/ForgotPassword";
import SignUp from "./features/user/SignUp";
import Login from "./features/user/Login";
import Logout from "./features/user/Logout";
import CheckEmail from "./features/onboarding/CheckEmail";
import CompanySignUp from "./features/onboarding/CompanySignUp";
import WizardContainer from "features/wizard";
import PlatformOptions from "features/wizard/setup/PlatformOptions";
import ScrollToTop from "features/wizard/utils/ScrollToTop";
import UserSettingsContainer from "features/user/user_settings";
import DashboardContainer from "./features/dashboard";
import ConfirmEmail from "features/onboarding/ConfirmEmail";
import SentryTesting from "./features/SentryTesting";
import ResetPassword from "./features/user/ResetPassword";

import {
  ONBOARDING_URLS,
  SETUP_URLS,
  USER_SETTINGS_URL_PREFIX,
  WIZARD_URL_PREFIX,
} from "./features/wizard/slice/urls";

import {
  DASHBOARD_URL_PREFIX,
  DASHBOARD_URL_WITH_SLUG,
} from "features/dashboard/urls";
import { VerifiedUserRoute } from "./UserRoutes";

export const APP_URLS = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  CHECK_EMAIL: "/check-email",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:uid/:token",
  CONFIRM_EMAIL: "/confirm-email/:token",
  SIGNUP: "/signup-f4943f98-edac-4fe0-8805-1247ec3ec27f",
  ACCOUNT: "/account",
  SENTRY_TEST: "/sentry-testing-console-872349084235",
};

export let refreshIntervalHandle: NodeJS.Timeout;

function App() {
  const { auth, isFetching, isError } = useSelector(userSelector);
  const dispatch = useDispatch();
  const email = auth.user?.email;

  let stored_user: any | null = {};
  try {
    stored_user = JSON.parse(localStorage.getItem("RE_auth_user") || "");
  } catch (e) {
    stored_user = null;
  }

  useEffect(() => {
    if (stored_user && !email && !isFetching && !isError) {
      console.log("You've been previously logged in, fetching profile...");
      dispatch(getProfile());
    }
  }, [email, dispatch, isFetching, isError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (email) {
      clearInterval(refreshIntervalHandle);
      refreshIntervalHandle = setInterval(
        () => {
          const needNewTokenCutoff = moment().add(60, "minutes");
          const expiration = moment(auth.access_token_expiration);
          const needNewToken =
            expiration.isBefore(needNewTokenCutoff) ||
            !auth.access_token_expiration;
          if (needNewToken) dispatch(refreshToken.Action(null));
        },
        1000 * 60 * 3
      );
    }
    return () => {
      clearInterval(refreshIntervalHandle);
    };
  }, [auth.access_token_expiration, email, dispatch]);

  return (
    <div className="App">
      <ThemeProvider theme={lightTheme}>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            <Route exact component={Login} path={APP_URLS.LOGIN} />
            <Route exact component={Logout} path={APP_URLS.LOGOUT} />
            <Route exact component={CheckEmail} path={APP_URLS.CHECK_EMAIL} />
            <Route
              exact
              component={ForgotPassword}
              path={APP_URLS.FORGOT_PASSWORD}
            />
            <Route
              exact
              component={ResetPassword}
              path={APP_URLS.RESET_PASSWORD}
            />
            <Route
              exact
              component={SentryTesting}
              path={APP_URLS.SENTRY_TEST}
            />
            <Route
              exact
              component={ConfirmEmail}
              path={APP_URLS.CONFIRM_EMAIL}
            />
            <VerifiedUserRoute
              exact
              component={CompanySignUp}
              path={ONBOARDING_URLS.company_signup}
            />

            <VerifiedUserRoute
              exact
              component={PlatformOptions}
              path={SETUP_URLS.platform_options}
            />
            <VerifiedUserRoute
              component={WizardContainer}
              path={WIZARD_URL_PREFIX}
            />
            <VerifiedUserRoute
              component={DashboardContainer}
              path={DASHBOARD_URL_WITH_SLUG}
            />
            <VerifiedUserRoute
              component={DashboardContainer}
              path={DASHBOARD_URL_PREFIX}
            />
            <VerifiedUserRoute
              component={UserSettingsContainer}
              path={USER_SETTINGS_URL_PREFIX}
            />

            <Route exact component={SignUp} path={APP_URLS.SIGNUP} />
            <VerifiedUserRoute component={DashboardContainer} path="*" />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
