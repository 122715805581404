import { EXPERIENCE_URLS, MECHANICS_URLS, SETTINGS_URLS } from "./urls";

import ExitSurveyIconGray from "../img/exitSurveyIconGray.svg";
import ExitSurveyIconPurple from "../img/exitSurveyIconPurple.svg";
import PauseIconGray from "../img/pauseIconGray.svg";
import PauseIconPurple from "../img/pauseIconPurple.svg";
import TreatmentIconGray from "../img/offerIconGray.svg";
import TreatmentIconPurple from "../img/offerIconPurple.svg";
import StyleIconGray from "../img/styleIconGray.svg";
import StyleIconPurple from "../img/styleIconPurple.svg";
import CodeIconGray from "../img/codeIconGray.svg";
import CodeIconPurple from "../img/codeIconPurple.svg";
import AdvancedIconGray from "../img/advancedIconGray.svg";
import AdvancedIconPurple from "../img/advancedIconPurple.svg";
import AnnouncementIconGray from "../img/announcementIconGray.svg";
import AnnouncementIconPurple from "../img/announcementIconPurple.svg";
import GamingIconGray from "../img/gamingIconGray.svg";
import GamingIconPurple from "../img/gamingIconPurple.svg";
import NotificationsIconGray from "../img/notificationsIconGray.svg";
import NotificationsIconPurple from "../img/notificationsIconPurple.svg";
import YourEngineIconGray from "../img/yourEngineIconGray.svg";
import YourEngineIconPurple from "../img/yourEngineIconPurple.svg";
import CancelledIconGray from "../img/cancelledIconGray.svg";
import CancelledIconPurple from "../img/cancelledIconPurple.svg";
import ExitIconGray from "../img/exitIconGray.svg";
import ExitIconPurple from "../img/exitIconPurple.svg";
import ExperienceTextsGray from "../img/experienceTextsGray.svg";
import ExperienceTextsPurple from "../img/experienceTextsPurple.svg";

export interface NavbarIcon {
  normal: string;
  selected: string;
  alt: string;
  title: string;
  target: string;
  step?: WizardStep;
}

export const ExperienceIconOrder: string[] = [];
export const ExperienceIcons: { [key: string]: NavbarIcon } = {};

function registerExperienceIcon(icon: NavbarIcon) {
  ExperienceIconOrder.push(icon.alt);
  ExperienceIcons[icon.alt] = icon;
}

export const MechanicsIconOrder: string[] = [];
export const MechanicsIcons: { [key: string]: NavbarIcon } = {};

function registerMechanicsIcon(icon: NavbarIcon) {
  MechanicsIconOrder.push(icon.alt);
  MechanicsIcons[icon.alt] = icon;
}

export const SettingsIconOrder: string[] = [];
export const SettingsIcons: { [key: string]: NavbarIcon } = {};

function registerSettingsIcon(icon: NavbarIcon) {
  SettingsIconOrder.push(icon.alt);
  SettingsIcons[icon.alt] = icon;
}

([
  {
    alt: "exit_survey",
    title: "Exit Survey",
    target: EXPERIENCE_URLS.exit_survey,
    normal: ExitSurveyIconGray,
    selected: ExitSurveyIconPurple,
  },
  {
    alt: "treatments",
    title: "Treatments",
    target: EXPERIENCE_URLS.treatments,
    normal: TreatmentIconGray,
    selected: TreatmentIconPurple,
  },
  {
    alt: "pause_options",
    title: "Pause Options",
    target: EXPERIENCE_URLS.pause_options,
    normal: PauseIconGray,
    selected: PauseIconPurple,
  },
  {
    alt: "cancelled",
    title: "Confirm Cancellation",
    target: EXPERIENCE_URLS.cancelled,
    normal: CancelledIconGray,
    selected: CancelledIconPurple,
  },
  {
    alt: "exit",
    title: "Final Exit Screen",
    target: EXPERIENCE_URLS.exit,
    normal: ExitIconGray,
    selected: ExitIconPurple,
  },
  {
    alt: "style",
    title: "Style",
    target: EXPERIENCE_URLS.style,
    normal: StyleIconGray,
    selected: StyleIconPurple,
  },
  {
    alt: "code",
    title: "CSS Editor",
    target: EXPERIENCE_URLS.css_editor,
    normal: CodeIconGray,
    selected: CodeIconPurple,
  },
  {
    alt: "experience_texts",
    title: "Experience Texts",
    target: EXPERIENCE_URLS.experience_texts,
    normal: ExperienceTextsGray,
    selected: ExperienceTextsPurple,
  },
] as NavbarIcon[]).map((icon: NavbarIcon) => registerExperienceIcon(icon));

([
  {
    alt: "exit_survey",
    title: "Exit Survey Response Flow",
    target: MECHANICS_URLS.exit_survey_response,
    normal: ExitSurveyIconGray,
    selected: ExitSurveyIconPurple,
  },
  {
    alt: "treatments",
    title: "Treatment Flow",
    target: MECHANICS_URLS.treatment_mechanics,
    normal: TreatmentIconGray,
    selected: TreatmentIconPurple,
  },
  {
    alt: "pause_options",
    title: "Pause Option Flow",
    target: MECHANICS_URLS.pause_mechanics,
    normal: PauseIconGray,
    selected: PauseIconPurple,
  },
] as NavbarIcon[]).map((icon: NavbarIcon) => registerMechanicsIcon(icon));

([
  {
    alt: "your_engine",
    title: "Your Engine",
    target: SETTINGS_URLS.your_engine,
    normal: YourEngineIconGray,
    selected: YourEngineIconPurple,
  },
  {
    alt: "notification_settings",
    title: "Notification Settings",
    target: SETTINGS_URLS.notification_settings,
    normal: NotificationsIconGray,
    selected: NotificationsIconPurple,
  },
  {
    alt: "gaming_prevention",
    title: "Gaming Prevention",
    target: SETTINGS_URLS.gaming_prevention,
    normal: GamingIconGray,
    selected: GamingIconPurple,
  },
  {
    alt: "announcement_banner",
    title: "Announcement Banner",
    target: SETTINGS_URLS.announcement_banner,
    normal: AnnouncementIconGray,
    selected: AnnouncementIconPurple,
  },
  {
    alt: "advanced",
    title: "Advanced",
    target: SETTINGS_URLS.advanced,
    normal: AdvancedIconGray,
    selected: AdvancedIconPurple,
  },
] as NavbarIcon[]).map((icon: NavbarIcon) => registerSettingsIcon(icon));

export interface WizardStep {
  path: string;
  title: string;
  icon?: string;
}

export const WizardStepOrder: string[] = [];

export const WizardSteps: { [key: string]: WizardStep } = {};

function registerWizardStep(step: WizardStep) {
  /* Create an array of steps for the Tour, each step identified uniquely by its path */
  WizardStepOrder.push(step.path);
  WizardSteps[step.path] = step;
  if (step.icon && ExperienceIcons[step.icon])
    ExperienceIcons[step.icon].step = step;
}

registerWizardStep({
  path: EXPERIENCE_URLS.exit_survey,
  title: "Exit Survey",
  icon: "exit_survey",
});
registerWizardStep({
  path: EXPERIENCE_URLS.treatments,
  title: "Treatments",
  icon: "treatments",
});
registerWizardStep({
  path: EXPERIENCE_URLS.pause_options,
  title: "Pause Options",
  icon: "pause_options",
});
registerWizardStep({
  path: EXPERIENCE_URLS.cancelled,
  title: "Cancelled",
  icon: "cancelled",
});
registerWizardStep({
  path: EXPERIENCE_URLS.exit,
  title: "Final Exit",
  icon: "exit",
});
registerWizardStep({
  path: EXPERIENCE_URLS.style,
  title: "Style",
  icon: "style",
});
registerWizardStep({
  path: EXPERIENCE_URLS.css_editor,
  title: "CSS Editor",
  icon: "code",
});
registerWizardStep({
  path: EXPERIENCE_URLS.experience_texts,
  title: "Experience Texts",
  icon: "experience_texts",
});
registerWizardStep({
  path: MECHANICS_URLS.exit_survey_response,
  title: "Exit Reponse Flow",
  icon: "mechanics",
});
registerWizardStep({
  path: MECHANICS_URLS.treatment_mechanics,
  title: "Treatment Flow",
  icon: "mechanics",
});
registerWizardStep({
  path: MECHANICS_URLS.pause_mechanics,
  title: "Pause Flow",
  icon: "mechanics",
});
registerWizardStep({
  path: SETTINGS_URLS.your_engine,
  title: "Your Engine",
  icon: "your_engine",
});
registerWizardStep({
  path: SETTINGS_URLS.notification_settings,
  title: "Notification Settings",
  icon: "notification_settings",
});
registerWizardStep({
  path: SETTINGS_URLS.gaming_prevention,
  title: "Gaming Prevention",
  icon: "gaming_prevention",
});
registerWizardStep({
  path: SETTINGS_URLS.announcement_banner,
  title: "Announcement Banner",
  icon: "announcement_banner",
});
registerWizardStep({
  path: SETTINGS_URLS.advanced,
  title: "Advanced Settings",
  icon: "advanced",
});
