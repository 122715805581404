import React from "react";
import styled from "@emotion/styled";

import Layout, { BottomWhite } from "../../components/Layout/Layout";
import Footer from "../../components/Footer";
import ResponseButton from "../../components/ResponseButton";
import { ThemeAttributeProps } from "../../theme";

export interface IReason {
  id: number;
  reason_text: string;
}

interface ExitSurveyScreenProps {
  title: string;
  reasons: IReason[];
  selected?: string;
  onReasonChange(id: string): void;
  website_url?: string;
  theme?: ThemeAttributeProps;
}

const ReasonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  width: 85%;
  max-width: 860px;
  margin: auto;
`;

const BottomWhiteWrapper = styled(BottomWhite)`
  padding-top: 2rem;
  display: block;
`;

interface ExitSurveyScreenState {
  selected?: string;
}

class ExitSurveyScreen extends React.Component<
  ExitSurveyScreenProps,
  ExitSurveyScreenState
> {
  constructor(props: ExitSurveyScreenProps) {
    super(props);
    this.state = {
      selected: props.selected,
    };
  }

  pickReason(id: string) {
    this.props.onReasonChange(id);
    this.setState({ selected: id });
  }

  goBackHome() {
    // this goes back to the previous page if it exists
    // otherwise it goes to the home url
    let goingBack = false;
    let changeBackUrl = () => {
      if (goingBack === false) {
        setTimeout(() => {
          window.location.href = this.props.website_url!;
        }, 300);
      }
    };
    window.addEventListener("beforeunload", function () {
      goingBack = true;
      changeBackUrl = () => {};
    });
    window.history.back();
    changeBackUrl();
  }

  buttonTextFilter(text: string, buttonId: string) {
    if (buttonId !== "other") return text;
    return (this.props.theme && this.props.theme.other_reason_button) || "";
  }

  render() {
    return (
      <>
        <Layout title={this.props.title} logo show_banner>
          <BottomWhiteWrapper className="bottom-content-container">
            <ReasonsWrapper className="exit-survey-buttons-wrapper">
              {this.props.reasons.map((reason: IReason, index: number) => {
                return (
                  <ResponseButton
                    key={index}
                    onClick={() => this.pickReason(reason.id.toString())}
                    buttonText={this.buttonTextFilter(
                      reason.reason_text,
                      reason.id.toString()
                    )}
                    buttonAdditionalText=""
                  />
                );
              })}
            </ReasonsWrapper>
          </BottomWhiteWrapper>
        </Layout>

        <Footer
          onClickBack={
            this.props.theme && this.props.theme.is_embedded
              ? undefined
              : () => {
                  this.goBackHome();
                }
          }
          onClickContinue={
            this.props.theme && !!this.props.theme.immediate_cancel_reason_id
              ? (e) =>
                  this.pickReason(
                    `${this.props.theme!.immediate_cancel_reason_id}`
                  )
              : undefined
          }
          thanksButtonText={
            this.props.theme && this.props.theme.immediate_cancel_text
          }
        />
      </>
    );
  }
}

export default ExitSurveyScreen;
