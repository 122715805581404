// Material UI
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

// images
import AttentionIcon from "./img/attentionIcon.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
}));

export interface AttentionBubbleProps {
  text: string;
}

export const AttentionBubble: FC<AttentionBubbleProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <Card className={classes.attentionBubble}>
      <Grid container alignItems="center" spacing={2} justifyContent="center">
        <Grid item xs={1}>
          <img src={AttentionIcon} alt="info_icon" />
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body2"
            className={clsx(classes.alignLeft, classes.lightPurple)}
          >
            <span className={classes.bold}>Note:</span> {text}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
