import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { forgotPassword, clearFormState, logoutUser } from "./userSlice";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { mergeErrors } from "app/forms";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// components
import TransparentTextField from "components/input/TransparentTextField";
import ExternalLayout from "components/layout/external/ExternalLayout";
import Loader from "components/loading/Loader";

// styles
import styles from "./userStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();
  const classes = useStyles();

  const isLoading = useSelector(forgotPassword.isFetchingSelector);
  const errors = mergeErrors(
    frontendErrors,
    useSelector(forgotPassword.ExtraErrorsSelector)
  );

  // On component load
  useEffect(() => {
    return () => {
      dispatch(clearFormState());
      dispatch(logoutUser());
    };
  }, [dispatch]);

  const recaptchaRef = React.useRef(null);
  const recaptchaKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
    "6LdqBNsUAAAAAI4Ukerea-ADwIsIFcavuLpB0PNu";

  const onSubmit = handleSubmit(({ email }) => {
    dispatch(clearFormState());
    // @ts-ignore
    const recaptchaValue = recaptchaRef.current?.getValue();
    if (!recaptchaValue || recaptchaValue.length < 1) {
      toast.error("Please complete the reCaptcha.");
      return;
    }
    dispatch(forgotPassword.Action(email));
  });

  return (
    <ExternalLayout footerFull>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={6} lg={4}>
          <form onSubmit={onSubmit}>
            <Card>
              <Typography variant="h1">Forgot Password</Typography>
              <Typography variant="body1">
                Let's help you reset your password
              </Typography>
              <FormGroup>
                <Controller
                  control={control}
                  name="email"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("email"))}
                      helperText={errors.get("email")}
                      label="YOUR EMAIL"
                      variant="outlined"
                    />
                  )}
                />
              </FormGroup>

              <FormGroup style={{ alignItems: "center", margin: "0 0 1rem 0" }}>
                <ReCAPTCHA sitekey={recaptchaKey} ref={recaptchaRef} />
              </FormGroup>

              {isLoading ? (
                <Loader />
              ) : (
                <Button
                  color="primary"
                  onClick={onSubmit}
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                >
                  Reset My Password
                </Button>
              )}

              <Link className={classes.registerLink} to="/login">
                Back to home
              </Link>
            </Card>
          </form>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
}
